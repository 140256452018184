import { useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import useMintNFT from "../../hooks/useMintNFT";
import { NormalButton } from "../../theme/components";
import { Loader } from "../Loader/small";
import "./index.scss";

const Wrapper = styled.div`
  display: block;
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 1;
  letter-spacing: -2px;
  text-shadow: 0 0 80px rgb(192 219 255 / 48%), 0 0 32px rgb(65 120 255 / 24%);
  color: rgba(255, 255, 255, 0.96);
  pointer-events: none;
`;

const TableWrapper = styled.div`
  transition: all 0.5s;
  max-height: calc(100vh - 350px);
  overflow: auto;

  table,
  th,
  td {
    padding: 16px;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    text-indent: 0;
  }

  th {
    background-color: rgba(255, 255, 255, 0.2);
    font-weight: 600;
    text-align: center;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    position: relative;
    margin: 0;
    min-width: 0;
    min-height: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    background-image: none;
    background-position: center center;
    background-repeat: no-repeat;
  }
`;

const Contact = styled.div`
  width: 100%;
  max-width: 100%;

  .border-radius-4 {
    border-radius: 4px;
  }

  .inputWrapper {
    min-width: 0;
    cursor: text;
    row-gap: 4px;
    padding-left: 8px;
    padding-right: 8px;
    opacity: 1;
    color: rgba(255, 255, 255, 0.88);
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: rgba(111, 76, 255, 0.04);
    border: 1px solid rgb(202 202 202 / 64%);
    border-radius: 4px;
    box-sizing: border-box;
    transition: border 0.3s ease;
    position: relative;
    pointer-events: all;

    input {
      color: rgba(255, 255, 255, 0.88);
      margin-bottom: 0px;
      width: 100%;
      height: 30px;
      border: none !important;
      font-family: EuclidCircular, sans-serif;
      font-size: 16px;
      background: transparent;
      outline: none;
    }

    &:hover {
      border: 1px solid rgb(255, 255, 255);
      background-color: rgba(228, 255, 76, 0.1);
    }
  }

  .svgWrapper {
    flex-shrink: 0;
    position: absolute;
    inset-inline-end: 0px;
    margin-bottom: -2px;

    button {
      width: 32px;
      height: 32px;

      .svgSpan {
        position: absolute;
        inset: 0px;
        border-radius: 9999px;
        background-color: rgb(111, 76, 255);
        opacity: 0;
        transform: scale(1);
        transition: color 200ms ease, background-color 200ms ease,
          border-color 200ms ease, text-decoration-color 200ms ease,
          fill 200ms ease, stroke 200ms ease, opacity 200ms ease,
          box-shadow 200ms ease, text-shadow 200ms ease, transform 200ms ease,
          filter 200ms ease, backdrop-filter 200ms ease;
      }

      .svgSpan2 {
        display: block;
        width: 16px;
        height: 16px;
        opacity: 0.32;
        transition: opacity 200ms ease;

        svg {
          width: 100%;
          height: 100%;
          fill: currentColor;
          transform: rotate(0deg);
        }
      }
    }
  }
`;

const EmptyWrapper = styled.div`
  font-size: 16px;
  border: 1px solid #50ab41;
  padding: 0.5rem 1rem;
  background: #348d1c5c;
  text-align: center;
`;

export const RankingModal = ({ isOpen, onClose, myCards }: any) => {
  const [searchToken, setSearchToken] = useState("");

  const { rankData, loadingRankData } = useMintNFT();

  const data =
    searchToken === ""
      ? rankData
      : rankData?.filter(
          (item: any) =>
            item.id == searchToken ||
            item.comment.toLowerCase().includes(searchToken.toLowerCase())
        );

  return (
    <Wrapper id="modal_app">
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        appElement={document.getElementById("modal_app")}
        className="rankingModal snowLift"
        overlayClassName="Overlay"
      >
        <div className="flex flex-col justify-between items-center h-full font-EuclidCircular">
          <Title className="text-white text-2xl font-bold my-4 mb-8 font-Outfit">
            Ranking
          </Title>

          <Contact className="mb-4">
            <div className="m-0 flex flex-col w-full">
              <div className="border-radius-4">
                <div className="inputWrapper m-0 flex flex-col">
                  <div className="m-0 flex items-center justify-center">
                    <input
                      value={searchToken}
                      onChange={(e: any) => setSearchToken(e.target.value)}
                      placeholder="Please type token Id or name to search..."
                      aria-label="Please type token Id or name to search..."
                    />

                    <div className="svgWrapper">
                      <button>
                        <span className="svgSpan"></span>

                        <div className="m-0 p-0 flex flex-col justify-center items-center w-full h-full">
                          <span className="svgSpan2">
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                clip-rule="evenodd"
                                d="M9.5 8C9.5 7.17157 8.82843 6.5 8 6.5C7.17157 6.5 6.5 7.17157 6.5 8C6.5 8.82843 7.17157 9.5 8 9.5C8.82843 9.5 9.5 8.82843 9.5 8ZM8 5.5C9.38071 5.5 10.5 6.61929 10.5 8C10.5 9.38071 9.38071 10.5 8 10.5C6.61929 10.5 5.5 9.38071 5.5 8C5.5 6.61929 6.61929 5.5 8 5.5Z"
                              ></path>
                              <path
                                fillRule="evenodd"
                                clip-rule="evenodd"
                                d="M6 8.5L1 8.5L1 7.5L6 7.5L6 8.5Z"
                              ></path>
                              <path
                                fillRule="evenodd"
                                clip-rule="evenodd"
                                d="M15.3536 8.35355L8.35355 15.3535L7.64645 14.6464L14.2929 7.99999L7.64645 1.35355L8.35355 0.646439L15.3536 7.64644C15.4473 7.74021 15.5 7.86738 15.5 7.99999C15.5 8.1326 15.4473 8.25978 15.3536 8.35355Z"
                              ></path>
                            </svg>
                          </span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Contact>

          <TableWrapper className={`font-EuclidCircular w-full relative mt-4`}>
            {loadingRankData ? (
              <div
                className="flex justify-center items-center relative"
                style={{ minHeight: "100px" }}
              >
                <Loader />
              </div>
            ) : (
              <>
                <table className="w-full">
                  <thead>
                    <tr>
                      <th align="center">Rank</th>
                      <th align="center">Token Id</th>
                      <th align="center">Name</th>
                      <th align="center">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data
                      ?.sort((a: any, b: any) => a.rank - b.rank)
                      .map((item: any, index: number) => (
                        <tr key={`rankdata${index}`}>
                          <td align="center">
                            {Number(item.rank) == 0 ? "" : Number(item.rank)}
                          </td>
                          <td align="center">{item.id}</td>
                          <td
                            align="center"
                            className="max-w-[200px] break-words"
                          >
                            {item.comment}
                          </td>
                          <td align="center">{item.status}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                {!data?.length ? (
                  <EmptyWrapper>Cannot find token.</EmptyWrapper>
                ) : null}
              </>
            )}
          </TableWrapper>

          <NormalButton className="mt-6" onClick={() => onClose()}>
            Close
          </NormalButton>
        </div>
      </Modal>
    </Wrapper>
  );
};

export default RankingModal;
