export const NETWORK_CONTEXT_NAME = "NETWORK";

export const CHAIN_ID = Object.freeze({
  MAINNET: 1,
  GOERLI: 5,
  SEPOLIA: 11155111,
  MATIC: 137,
  MATIC_TESTNET: 80001,
  FANTOM: 250,
  FANTOM_TESTNET: 4002,
  XDAI: 100,
  BSC: 56,
  BSC_TESTNET: 97,
  ARBITRUM: 42161,
  ARBITRUM_TESTNET: 421614,
  MOONBASE: 1287,
  AVALANCHE: 43114,
  FUJI: 43113,
  HECO: 128,
  HECO_TESTNET: 256,
  HARMONY: 1666600000,
  HARMONY_TESTNET: 1666700000,
});

export const MAIN_NETWORK_CHAIN_ID = CHAIN_ID.ARBITRUM;

export const ContractAddress = {
  [CHAIN_ID.ARBITRUM]: {
    mint: process.env.REACT_APP_GOERLI_CONTRACT_MINT_ADDRESS!,
  },
};

export const MoralisAPIKey = {
  serverUrl: process.env.REACT_APP_MORALIS_SERVER_URL,
  appId: process.env.REACT_APP_MORALIS_API_KEY,
};

export const AlchemyAPIKey = process.env.REACT_APP_ALCHEMY_API_KEY;
