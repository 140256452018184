import { Component } from "react";
import * as PIXI from "pixi.js";
import { pauseEvent } from "../../utils/helper";
import { levelArea, range, zoomRange } from "../../constants";
import { getRandomValues } from "../../helper/math";
import MountainMap from "../../assets/images/mountains/mountain_3840_2160-min.png";

type MapViewProps = {
  mapCenterPos: any;
  updateMapCenterPos: any;
  zoomLevel: any;
  updateZoomLevel: any;
  myCards: any;
  totalSupply: any;
};

export default class MapView extends Component<MapViewProps> {
  app: any;
  pointDownPos: any;
  moveDistance: any;
  spritesArray: any;
  textArray: any;

  constructor(props: MapViewProps) {
    super(props);

    this.onPointerDownHandler = this.onPointerDownHandler.bind(this);
    this.onPointerMoveHandler = this.onPointerMoveHandler.bind(this);
    this.onPointerUpHandler = this.onPointerUpHandler.bind(this);
    this.onWheelHandler = this.onWheelHandler.bind(this);
    this.onTouchStartHandler = this.onTouchStartHandler.bind(this);
    this.onTouchMoveHandler = this.onTouchMoveHandler.bind(this);
    this.onTouchEndHandler = this.onTouchEndHandler.bind(this);
  }

  componentDidMount(): void {
    const spritesArray: any[] = [];
    this.spritesArray = spritesArray;
    const textArray: any[] = [];
    this.textArray = textArray;

    const onReady = () => {
      this.app = new PIXI.Application({
        resizeTo: window,
        backgroundAlpha: 0,
        width: window.innerWidth,
        height: window.innerHeight,
      });

      document.getElementById("mapContainer")?.appendChild(this.app.view);

      /**
       * Add mountain background image sprite
       */
      const mapImg = PIXI.Sprite.from(MountainMap);
      this.app.stage.addChild(mapImg);

      const graphics = new PIXI.Graphics();
      this.app.stage.addChild(graphics);

      const container = new PIXI.Container();
      // const container = new PIXI.ParticleContainer(100000, {
      //     scale: true,
      //     position: true,
      //     rotation: true,
      //     uvs: true,
      //     alpha: true,
      // })
      // container._batchSize = 16383
      this.app.stage.addChild(container);

      this.props.myCards.forEach((card: any) => {
        const texture = PIXI.Texture.from(card.mapImage);
        const player_marker = new PIXI.Sprite(texture) as any;

        const result = getRandomValues(
          (levelArea as any)[card.level].position[0],
          (levelArea as any)[card.level].position[1],
          1
        );

        player_marker.mapPosition = {
          x: result[0].x,
          y: result[0].y,
        };

        player_marker.size = (levelArea as any)[card.level].size;
        spritesArray.push(player_marker);
        container.addChild(player_marker);

        const style = new PIXI.TextStyle({
          fontFamily: "EuclidCircular",
          fontSize: 20,
          fontWeight: "bold",
          fill: ["#ffffff", "#00ff99"], // gradient
          dropShadow: true,
          dropShadowColor: "#000000",
          dropShadowBlur: 4,
          dropShadowAngle: Math.PI / 6,
          dropShadowDistance: 6,
        });

        const text = new PIXI.Text(card.comment, style) as any;
        text.anchor.set(0.5);
        text.mapPosition = {
          x: player_marker.mapPosition.x,
          y: player_marker.mapPosition.y,
        };
        textArray.push(text);
        this.app.stage.addChild(text);
      });

      this.app.ticker.add(() => {
        mapImg.anchor.set(0.5);
        mapImg.width = range.x * this.props.zoomLevel;
        mapImg.height = range.y * this.props.zoomLevel;
        mapImg.x = this.props.mapCenterPos.x;
        mapImg.y = this.props.mapCenterPos.y;

        for (let i = 0; i < spritesArray.length; i++) {
          const sprite = spritesArray[i];
          sprite.anchor.set(0.5);
          sprite.width = sprite.size * this.props.zoomLevel * 2;
          sprite.height = sprite.size * this.props.zoomLevel * 2;
          sprite.x =
            this.props.mapCenterPos.x -
            sprite.mapPosition.x * this.props.zoomLevel;
          sprite.y =
            this.props.mapCenterPos.y -
            sprite.mapPosition.y * this.props.zoomLevel;
        }

        for (let i = 0; i < textArray.length; i++) {
          const text = textArray[i];
          text.anchor.set(0.5, 0);
          text.x =
            this.props.mapCenterPos.x -
            text.mapPosition.x * this.props.zoomLevel;
          text.y =
            this.props.mapCenterPos.y -
            text.mapPosition.y * this.props.zoomLevel +
            spritesArray[i].size * this.props.zoomLevel;
          text.style.fontSize = 5 * this.props.zoomLevel;
        }
      });

      this.app.view.addEventListener(
        "pointerdown",
        this.onPointerDownHandler,
        false
      );
      this.app.view.addEventListener(
        "pointermove",
        this.onPointerMoveHandler,
        false
      );
      this.app.view.addEventListener(
        "pointerout",
        this.onPointerUpHandler,
        false
      );
      window.addEventListener("pointerup", this.onPointerUpHandler, false);
      this.app.view.addEventListener("wheel", this.onWheelHandler, false);

      this.app.view.addEventListener(
        "touchstart",
        this.onTouchStartHandler,
        false
      );
      this.app.view.addEventListener(
        "touchmove",
        this.onTouchMoveHandler,
        false
      );
      this.app.view.addEventListener("touchend", this.onTouchEndHandler, false);
      window.addEventListener("touchcancel", this.onTouchEndHandler, false);
    };

    onReady();
  }

  componentWillUnmount() {
    this.app.view.removeEventListener(
      "pointerdown",
      this.onPointerDownHandler,
      false
    );
    this.app.view.removeEventListener(
      "pointermove",
      this.onPointerMoveHandler,
      false
    );
    this.app.view.removeEventListener(
      "pointerout",
      this.onPointerUpHandler,
      false
    );
    window.removeEventListener("pointerup", this.onPointerUpHandler, false);
    this.app.view.removeEventListener("wheel", this.onWheelHandler, false);

    this.app.view.removeEventListener(
      "touchstart",
      this.onTouchStartHandler,
      false
    );
    this.app.view.removeEventListener(
      "touchmove",
      this.onTouchMoveHandler,
      false
    );
    this.app.view.removeEventListener(
      "touchend",
      this.onTouchEndHandler,
      false
    );
    window.removeEventListener("touchcancel", this.onTouchEndHandler, false);

    this.app.destroy(true, {
      children: true,
      texture: true,
      baseTexture: true,
    });
  }

  onPointerDownHandler(e: any) {
    pauseEvent(e);
    const clickPosition = {
      x: e.offsetX,
      y: e.offsetY,
    };

    this.pointDownPos = clickPosition;
    this.moveDistance = 0;
  }

  onPointerMoveHandler(e: any) {
    pauseEvent(e);
    if (!this.pointDownPos) return;

    const clickPosition = {
      x: e.offsetX,
      y: e.offsetY,
    };

    const diff = {
      x: clickPosition.x - this.pointDownPos.x,
      y: clickPosition.y - this.pointDownPos.y,
    };

    const newCenterPosition = {
      x: this.props.mapCenterPos.x + diff.x,
      y: this.props.mapCenterPos.y + diff.y,
    };

    this.props.updateMapCenterPos(newCenterPosition);

    this.pointDownPos = clickPosition;
  }

  onPointerUpHandler(e: any) {
    pauseEvent(e);
    this.pointDownPos = null;
  }

  onWheelHandler(e: any) {
    pauseEvent(e);

    let newLevel;
    if (e.deltaY > 0) {
      newLevel = this.props.zoomLevel - 1;
    } else {
      newLevel = this.props.zoomLevel + 1;
    }

    if (newLevel > zoomRange.max) newLevel = zoomRange.max;
    if (newLevel < zoomRange.min) newLevel = zoomRange.min;

    this.props.updateZoomLevel(newLevel);

    const newPosition = {
      x:
        this.app.view.width / 2 +
        ((this.props.mapCenterPos.x - this.app.view.width / 2) * newLevel) /
          this.props.zoomLevel,
      y:
        this.app.view.height / 2 +
        ((this.props.mapCenterPos.y - this.app.view.height / 2) * newLevel) /
          this.props.zoomLevel,
    };

    this.props.updateMapCenterPos(newPosition);
  }

  onTouchStartHandler(e: any) {}

  onTouchMoveHandler(e: any) {}

  onTouchEndHandler(e: any) {}

  render() {
    return (
      <div className="overflow-hidden max-h-screen max-w-screen">
        <div className="w-full h-full top-0 left-0" id="mapContainer"></div>
      </div>
    );
  }
}
