import { List } from "react-feather";
import styled from "styled-components";
import MapHeader from "../../components/Header/mapHeader";
import { Loader } from "../../components/Loader";
import RankingModal from "../../components/RankingModal";
import { ApplicationModal } from "../../constants";
import { useActiveWeb3React } from "../../hooks";
import { useModalOpen, useModalToggle } from "../../hooks/store";
import useNFTAlchemy from "../../hooks/useNFTAlchemy";
import useStore from "../../store";
import { NormalButton } from "../../theme/components";
import MapView from "./mapView";
import MiniMapView from "./miniMapView";
import useMintNFT from "../../hooks/useMintNFT";
import { FloatingTimer } from "./components";

const EmptyWrapper = styled.div`
  font-size: 2rem;
  border: 1px solid #50ab41;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background: #348d1c5c;
`;

const ListBtn = styled.div`
  top: 5.5rem;
  right: 2rem;
`;

export const Map = () => {
  const { account } = useActiveWeb3React();
  const { totalSupply } = useMintNFT();
  const { myNFTData, loading } = useNFTAlchemy();

  const mapCenterPos = useStore((state: any) => state.mapCenterPos);
  const updateMapCenterPos = useStore((state: any) => state.updateMapCenterPos);

  const zoomLevel = useStore((state: any) => state.zoomLevel);
  const updateZoomLevel = useStore((state: any) => state.updateZoomLevel);

  const toggleModal = useModalToggle(ApplicationModal.RANKING);

  const modalOpen = useModalOpen(ApplicationModal.RANKING);

  return (
    <>
      <MapHeader />

      {!account ? (
        <div className="w-full h-screen flex justify-center items-center">
          <EmptyWrapper>You didn't connect wallet.</EmptyWrapper>
        </div>
      ) : loading ? (
        <Loader />
      ) : (
        <>
          <MapView
            mapCenterPos={mapCenterPos}
            updateMapCenterPos={updateMapCenterPos}
            zoomLevel={zoomLevel}
            updateZoomLevel={updateZoomLevel}
            myCards={myNFTData}
            totalSupply={totalSupply}
          />

          <MiniMapView
            mapCenterPos={mapCenterPos}
            updateMapCenterPos={updateMapCenterPos}
            zoomLevel={zoomLevel}
            updateZoomLevel={updateZoomLevel}
          />
          {/* <FloatingTimer /> */}
          <ListBtn className="absolute">
            <NormalButton
              className="flex justify-center items-center"
              onClick={() => toggleModal()}
            >
              {" "}
              <List />{" "}
            </NormalButton>
          </ListBtn>

          <RankingModal
            isOpen={modalOpen}
            onClose={toggleModal}
            myCards={myNFTData}
          />
        </>
      )}
    </>
  );
};

export default Map;
