import { useWeb3React } from '@web3-react/core'
import { useCallback, useEffect } from 'react'
import { MAIN_NETWORK_CHAIN_ID } from "../constants";

export default function Updater() {
	const { active, library, chainId } = useWeb3React();

	const switchNetwork = useCallback(async () => {
		await library.provider.request({
			method: "wallet_switchEthereumChain",
			params: [{ chainId: "0x" + MAIN_NETWORK_CHAIN_ID.toString(16) }],
		});
	}, [library, chainId]);

	useEffect(() => {
		if (active && chainId !== MAIN_NETWORK_CHAIN_ID) {
			switchNetwork();
		}
	}, [library, chainId, active]);

	return null;
}
