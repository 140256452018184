import styled from "styled-components";

export const InputButton = styled.button`
	margin-left: 0.5rem;
	margin-right: 0.5rem;

	:hover {
		cursor: pointer;

		svg {
			stroke: #986db2 !important;
		}
	}

	:disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}
`;

export const NormalButton = styled.button`
	height: 48px;
	padding-left: 24px;
	padding-right: 24px;
	border-radius: 4px;
	border: 1px solid transparent;
	transition: color 400ms ease, background-color 400ms ease,
		border-color 400ms ease, text-decoration-color 400ms ease, fill 400ms ease,
		stroke 400ms ease, opacity 400ms ease, box-shadow 400ms ease,
		text-shadow 400ms ease, transform 400ms ease, filter 400ms ease,
		backdrop-filter 400ms ease;
	color: rgba(255, 255, 255, 0.88);
	background-color: rgba(111, 76, 255, 0.64);
	font-size: 14px;
	letter-spacing: 0.4px;
	line-height: 20px;
	font-weight: 600;
	font-family: "Outfit", cursive;
	font-size: 18px;
	line-height: 20px;
	text-decoration: none;
	color: #fff;
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
	outline: none;

	position: relative;
	overflow: visible; /* IE9 & 10 */
	-webkit-transition: 500ms linear;
	-moz-transition: 500ms linear;
	-o-transition: 500ms linear;
	transition: 500ms linear;

	&:hover {
		background-color: rgb(139 127 255 / 64%);
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.75), 0 0 40px #fff;
		box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.25),
			/* highlight */ inset 0 0 6px #da3b2c,
			/* inner glow */ inset 0 80px 80px -40px #dd4330,
			/* gradient */ 1px 1px 3px rgba(0, 0, 0, 0.75); /* shadow */

		.tooltip {
			display: block;
		}
	}

	&:focus {
		outline: none;
	}

	&:active {
		box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.25),
			/* highlight */ inset 0 0 6px #da3b2c,
			/* inner glow */ inset 0 80px 80px -40px #dd4330,
			/* gradient */ 0px 1px 0px rgba(255, 255, 255, 0.25); /* shadow */

		-webkit-transition: 50ms linear;
		-moz-transition: 50ms linear;
		-o-transition: 50ms linear;
		transition: 50ms linear;
	}

	&:disabled,
	&[disabled] {
		cursor: not-allowed;
		opacity: 0.5;
	}

	@keyframes moveup {
		0% {
			transform: translateY(10px) rotate(25deg);
			opacity: 0;
		}
		100% {
			transform: translateY(0) rotate(0deg);
			opacity: 1;
		}
	}

	.tooltip {
		position: absolute; // absolute to button
		background-color: #333;
		border-radius: 5px;
		font-size: 14px;
		color: #eee;
		display: none; //hide the tooltips
		animation: moveup 0.1s linear;
		width: auto;
		top: 0%;
		padding: 5px;
		min-inline-size: 200px;

		&:after {
			position: absolute;
			content: "";
			// top: 100%; //to set the triangle at end
			// left: 50%; // left side moved 10% so it looks like tooltip
			// this border property makes the triangle
			border-top: 5px solid transparent;
			border-right: 5px solid transparent;
			border-bottom: 5px solid transparent;
			border-left: 5px solid transparent;
		}

		&.left {
			right: 110%;

			&:after {
				border-left: 5px solid #333;
				top: 10%;
				left: 100%;
			}
		}

		&.right {
			left: 110%;

			&:after {
				border-right: 5px solid #333;
				top: 10%;
				right: 100%;
			}
		}
	}
`;
export const LinkButton = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 48px;
	padding-left: 24px;
	padding-right: 24px;
	border-radius: 4px;
	border: 1px solid transparent;
	transition: color 400ms ease, background-color 400ms ease,
		border-color 400ms ease, text-decoration-color 400ms ease, fill 400ms ease,
		stroke 400ms ease, opacity 400ms ease, box-shadow 400ms ease,
		text-shadow 400ms ease, transform 400ms ease, filter 400ms ease,
		backdrop-filter 400ms ease;
	color: rgba(255, 255, 255, 0.88);
	background-color: rgba(111, 76, 255, 0.64);
	font-size: 14px;
	letter-spacing: 0.4px;
	line-height: 20px;
	font-weight: 600;
	font-family: "Outfit", cursive;
	font-size: 18px;
	line-height: 20px;
	text-decoration: none;
	color: #fff;
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
	outline: none;

	position: relative;
	overflow: visible; /* IE9 & 10 */
	-webkit-transition: 500ms linear;
	-moz-transition: 500ms linear;
	-o-transition: 500ms linear;
	transition: 500ms linear;

	&:hover {
		background-color: rgb(139 127 255 / 64%);
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.75), 0 0 40px #fff;
		box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.25),
			/* highlight */ inset 0 0 6px #da3b2c,
			/* inner glow */ inset 0 80px 80px -40px #dd4330,
			/* gradient */ 1px 1px 3px rgba(0, 0, 0, 0.75); /* shadow */

		.tooltip {
			display: block;
		}
	}

	&:focus {
		outline: none;
	}

	&:active {
		box-shadow: inset 1px 1px 0px rgba(255, 255, 255, 0.25),
			/* highlight */ inset 0 0 6px #da3b2c,
			/* inner glow */ inset 0 80px 80px -40px #dd4330,
			/* gradient */ 0px 1px 0px rgba(255, 255, 255, 0.25); /* shadow */

		-webkit-transition: 50ms linear;
		-moz-transition: 50ms linear;
		-o-transition: 50ms linear;
		transition: 50ms linear;
	}

	&:disabled,
	&[disabled] {
		cursor: not-allowed;
		opacity: 0.5;
	}

	@keyframes moveup {
		0% {
			transform: translateY(10px) rotate(25deg);
			opacity: 0;
		}
		100% {
			transform: translateY(0) rotate(0deg);
			opacity: 1;
		}
	}

	.tooltip {
		position: absolute; // absolute to button
		background-color: #333;
		border-radius: 5px;
		font-size: 14px;
		color: #eee;
		display: none; //hide the tooltips
		animation: moveup 0.1s linear;
		width: auto;
		top: 0%;
		padding: 5px;
		min-inline-size: 200px;

		&:after {
			position: absolute;
			content: "";
			// top: 100%; //to set the triangle at end
			// left: 50%; // left side moved 10% so it looks like tooltip
			// this border property makes the triangle
			border-top: 5px solid transparent;
			border-right: 5px solid transparent;
			border-bottom: 5px solid transparent;
			border-left: 5px solid transparent;
		}

		&.left {
			right: 110%;

			&:after {
				border-left: 5px solid #333;
				top: 10%;
				left: 100%;
			}
		}

		&.right {
			left: 110%;

			&:after {
				border-right: 5px solid #333;
				top: 10%;
				right: 100%;
			}
		}
	}
`;
