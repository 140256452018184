import styled from "styled-components";
import { LinkButton } from "../../../theme/components";
import { CommunityImageSlider } from "./CommunityImageSlider";

const Wrapper = styled.section`
	padding-top: 10rem !important;
	padding-bottom: 10rem !important;
	margin-top: 100px;
`;

const Content = styled.div`
	width: 85%;
	margin: auto;
	text-align: center;

	display: flex;
	align-items: center;
	gap: 3rem;

	@media (max-width: 1024px) {
		flex-direction: column;
		gap: 5rem;
	}

	@media (max-width: 768px) {
		width: 100%;
	}
`;

const Title = styled.h2`
	font-weight: 600;
	font-size: 3.75rem;
	text-shadow: 0 0 80px rgb(192 219 255 / 48%), 0 0 32px rgb(65 120 255 / 24%);
	letter-spacing: -1.6px;
	line-height: 4rem;
	color: rgba(255, 255, 255, 0.96);
	margin-bottom: 1.2rem;

	.newline {
		display: none;
	}

	@media (max-width: 768px) {
		font-size: 2rem;
		line-height: 3rem;
	}
`;

const Description = styled.p`
	font-size: 1.25rem;
	line-height: 2rem;
	margin: 0px;
	color: rgba(255, 255, 255, 0.88);

	@media (max-width: 768px) {
		width: 75%;
		margin: auto;
	}
	a {
		color: rgba(111, 76, 255, 1);
		&:hover {
			color: rgba(111, 76, 255, 0.75);
		}
	}
`;

const LeftPanel = styled.div`
	width: 50%;

	@media (max-width: 1024px) {
		width: 90%;
	}
`;

const RightPanel = styled(LeftPanel)`
	.slick-dots {
		li {
			&.slick-active {
				button {
					&::before {
						font-size: 15px;
					}
				}
			}

			button {
				&::before {
					font-size: 10px;
					color: white;
				}
			}
		}
	}
`;

export const CommunityInfo = () => (
	<Wrapper className="relative">
		<div>
			<Content className="relative">
				<LeftPanel>
					<Title>Who are the LToads?</Title>

					<Description className="font-EuclidCircular">
						The Link Marine TOADS (or LTOADS) are a community of highly
						motivated individuals from all layers of Chainlink society. Brought
						together under the TOADAO, their sole mission is to help spread the
						good word on all things Chainlink. In true Marine fashion, they are
						here to help, all in this together and ready to spoonfeed at the
						drop of a hat. LTOADS make themselves useful by running educational
						campaigns explaining Chainlinked products/platforms (so far
						generating millions of impressions on X), hosting well-attended
						Spaces on Chainlink-related topics and connecting communities.
						Our&nbsp;
						<a
							href=" https://opensea.io/collection/ltoad"
							target="_blank"
							rel="noopener noreferrer"
						>
							NFT
						</a>{" "}
						is the core of our community!
					</Description>
					<div className="flex justify-center items-center w-full my-4 mt-8">
						<LinkButton
							href="https://www.ltoad.com/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Our Community
						</LinkButton>
					</div>
				</LeftPanel>

				<RightPanel>
					<CommunityImageSlider />
				</RightPanel>
			</Content>
		</div>
	</Wrapper>
);
