import { Twitter } from "react-feather";
import styled from "styled-components";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
	padding-top: 32px;
	padding-bottom: 32px;
`;

const ContentWrapper = styled.div`
	flex-wrap: nowrap;
	row-gap: 32px;

	nav {
		ul {
			padding: 0;
			list-style: none;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			row-gap: 8px;

			li {
				margin: 0;
				padding: 0;
				list-style: none;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: 0px;
				padding: 0 1rem;
				cursor: pointer;

				a {
					color: rgba(255, 255, 255, 0.88);
					transition: color 200ms ease, background-color 200ms ease,
						border-color 200ms ease, text-decoration-color 200ms ease,
						fill 200ms ease, stroke 200ms ease;

					&.subNav {
						font-size: 14px;
						line-height: 21px;
						color: rgba(255, 255, 255, 0.48);
					}

					&:hover {
						color: rgb(255, 255, 255);
					}
				}
			}
		}
	}
`;

const Spacer = styled.div`
	height: 150px;
`;

export const Footer = () => {
	const navigate = useNavigate();

	return (
		<div className="relative">
			<Spacer />

			<Wrapper>
				<ContentWrapper className="m-0 p-0 flex flex-col justify-start items-center">
					<nav>
						<ul>
							<li>
								<Link smooth={true} duration={500} spy={true} to={"home"}>
									Home
								</Link>
							</li>

							<li>
								<Link smooth={true} duration={500} spy={true} to={"mint"}>
									Mint
								</Link>
							</li>

							<li>
								<button onClick={() => navigate("/map")}>The Mountain</button>
							</li>

							<li>
								<Link smooth={true} duration={500} spy={true} to={"nft"}>
									NFT
								</Link>
							</li>

							<li>
								<Link smooth={true} duration={500} spy={true} to={"faq"}>
									FAQ
								</Link>
							</li>

							<li onClick={() => navigate("/whitepaper")}>WhitePaper</li>
						</ul>

						<ul className="mt-8">
							<li>
								<a className="subNav">Privacy Policy</a>
							</li>

							<li>
								<a className="subNav">Terms of Service</a>
							</li>

							<li>
								<a className="subNav">Partnership requests</a>
							</li>
						</ul>

						<ul className="my-8">
							<li>
								<Twitter size={18} />
							</li>
						</ul>
					</nav>
				</ContentWrapper>
			</Wrapper>
		</div>
	);
};

export default Footer;
