export enum PlayerTicketStatus {
	Climbing = "still climbing",
	Eliminated = "eliminated",
	Winner = "winner",
}

export type PlayerTicketInfo = {
	id: number;
	rank: number;
	comment: string;
	status: PlayerTicketStatus;
};
