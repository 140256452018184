// import { BigNumber } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useActiveWeb3React } from ".";
import { useTransactionAdder } from "./store/transactions";
import { useMintContract } from "./useContract";
import { PlayerTicketInfo, PlayerTicketStatus } from "../types";

const useMintNFT = () => {
  enum STATUS {
    stopped,
    minting,
    eliminations,
    finished,
  }

  const { account, chainId } = useActiveWeb3React();
  const mintContract = useMintContract(true);

  const [mintCostBigNumber, setMintCostBigNumber] = useState(0);

  const [rankData, setRankData] = useState<PlayerTicketInfo[]>();
  const [totalSupply, setTotalSupply] = useState(0) as any;
  const [status, setStatus] = useState(0) as any;
  const [elimination_start_time, set_elimination_start_time] =
    useState<number>(0);
  const [loadingRankData, setLoadingRankData] = useState(true) as any;

  const addTransaction = useTransactionAdder() as any;

  const fetchMintInfo = useCallback(async () => {
    if (mintContract) {
      try {
        const result_mintCost = await mintContract?.price();
        const vrf_mintCost = await mintContract?.vrf_cost();

        setMintCostBigNumber(Number(result_mintCost) + Number(vrf_mintCost));
      } catch (e) {
        console.error("fetchMintInfo ------ : ", e);
      }
    }
  }, [mintContract, account, chainId]);

  const fetchTokenStatus = useCallback(async () => {
    if (mintContract) {
      try {
        let amount = await mintContract?.totalSupply();
        setTotalSupply(amount);

        set_elimination_start_time(await mintContract?.eliminatnion_time());

        const status_ = await mintContract?.status();

        setStatus(status_);

        const result: PlayerTicketInfo[] = [];
        for (let i = 1; i <= amount; i++) {
          let t;
          try {
            t = await mintContract?.tickets(i);
          } catch (error: any) {
            console.error(error.reason);
          }

          let status = PlayerTicketStatus.Climbing;

          if (t.rank != 0) status = PlayerTicketStatus.Eliminated;
          if (t.rank == 1) status = PlayerTicketStatus.Winner;

          result.push({
            id: Number(i),
            rank: t.rank,
            comment: t.name,
            status,
          });
        }

        setRankData(result);
        setLoadingRankData(false);
      } catch (e) {
        console.error("fetchTokenStatus ------ : ", e);
      }
    }
  }, [mintContract, account, chainId]);

  useEffect(() => {
    // fetchMintInfo();
    fetchTokenStatus();

    // const refreshInterval = setInterval(fetchMintInfo, 10000);
    // return () => clearInterval(refreshInterval);
  }, [mintContract, account, chainId]);

  //* function for refreshing the rankData, to get the last eliminated ticket
  const refreshRankData = useCallback(() => {
    fetchTokenStatus();
  }, [fetchTokenStatus]);

  const mint = useCallback(
    async (input: any) => {
      const status_ = await mintContract?.status();
      if (status_ != STATUS.minting) {
        NotificationManager.warning("Minting not active.");
        return false;
      }

      const result_mintCost = await mintContract?.price();
      const vrf_mintCost = await mintContract?.vrf_cost();

      try {
        const tx = await mintContract?.mint(input.comment, {
          from: account,
          value: String(Number(result_mintCost) + Number(vrf_mintCost)),
        });
        addTransaction(tx, { summary: `Mint succeed!` });
        return tx;
      } catch (e: any) {
        console.error("mint -------", e);
        if (
          JSON.stringify(e).indexOf(
            "execution reverted: Elimination has been started"
          ) !== -1
        )
          NotificationManager.warning("Elimination has been started.");
        else if (await mintContract?.namesTaken(input.comment))
          NotificationManager.warning(`Name ${input.comment} already taken.`);
        else
          NotificationManager.warning(
            "Minting error:" + e.data.message.split(":")[1]
          );
        return e;
      }
    },
    [mintContract]
  );

  const claimRewards = useCallback(
    async (input: any) => {
      try {
        const tx = await mintContract?.claimreward(input.index);
        addTransaction(tx, { summary: `Claim succeed!` });
        return tx;
      } catch (e: any) {
        console.error("claimRewards ---------", e);
        return e;
      }
    },
    [mintContract]
  );

  return {
    mintCostBigNumber,
    mint,
    rankData,
    loadingRankData,
    refreshRankData,
    claimRewards,
    totalSupply,
    status,
    elimination_start_time,
  };
};

export default useMintNFT;
