import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ToadLilypad from "../images/1-lilypad.webp";
import ToadPraying from "../images/6-pepe_pray.webp";
import ToadGeneral from "../images/13-general_pepe.webp";
import ToadNfts from "../images/14-pepe_making_nfts.webp";
import ToadSnowboarding from "../images/42-pepe_snowboarding.webp";
import ToadSkiing from "../images/43-pepe_skiing.webp";
import ToadSnowmobile from "../images/44-pepe_snowmobile.webp";
import ToadRafting from "../images/45-pepe_whiterafting_rafting.webp";
import ToadGoldChain from "../images/49-pepe_gold_chain.webp";

const ImageWrapper = styled.div`
	background-color: rgba(12, 10, 29, 1);
	border: 1px solid rgba(255, 255, 255, 0.16);
	padding: 24px;
	border-radius: 8px;
	position: relative;

	.wrapper {
		width: 100% !important;
		padding: 0 !important;
	}

	div {
		width: 100%;
		padding: 1rem;
		position: relative;

		img {
			width: 100%;
		}
	}
`;

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	draggable: true,
};

const ImageList = [
	{
		src: ToadLilypad,
		alt: "lilipad toad",
	},
	{
		src: ToadPraying,
		alt: "toad pepe pray",
	},
	{
		src: ToadGeneral,
		alt: "toad general pepe",
	},
	{
		src: ToadNfts,
		alt: "toad pepe making nfts",
	},
	{
		src: ToadSnowboarding,
		alt: "toad pepe snowboarding",
	},
	{
		src: ToadSkiing,
		alt: "toad pepe skiing",
	},
	{
		src: ToadSnowmobile,
		alt: "toad pepe snowmobile",
	},
	{
		src: ToadRafting,
		alt: "toad pepe rafting",
	},
	{
		src: ToadGoldChain,
		alt: "toad pepe with gold chain",
	},
];

export const CommunityImageSlider = () => (
	<Slider {...settings}>
		{ImageList.map((image) => (
			<ImageWrapper key={image.alt}>
				<div className="wrapper relative flex flex-wrap">
					<div>
						<img src={image.src} alt={image.alt} />
					</div>
				</div>
			</ImageWrapper>
		))}
	</Slider>
);
