import { Alchemy, Network } from "alchemy-sdk";
import { useState, useEffect, useCallback } from "react";
import {
  AlchemyAPIKey,
  ContractAddress,
  MAIN_NETWORK_CHAIN_ID,
} from "../constants";
import { useActiveWeb3React } from ".";
import { useBlockNumber } from "./store";
import { useMintContract } from "./useContract";
import useMintNFT from "./useMintNFT";

const config = {
  apiKey: AlchemyAPIKey,
  network: Network.ARB_MAINNET,
};

const alchemy = new Alchemy(config);

const useNFTAlchemy = () => {
  const { account } = useActiveWeb3React() as any;
  const currentBlockNumber = useBlockNumber();
  const mintContract = useMintContract(true) as any;

  const [myNFTData, setMyNFTData] = useState([]) as any;
  const [loading, setLoading] = useState(true);

  const fetchNFTsForContract = useCallback(async () => {
    const nfts = await alchemy.nft.getNftsForOwner(account, {
      contractAddresses: [ContractAddress[MAIN_NETWORK_CHAIN_ID].mint],
    });
    const data = nfts.ownedNfts as any;

    let tiers = [];

    for (let i = 0; i < 5; i++) {
      tiers.push(await mintContract.Rewardtiers(i));
    }

    // for (let i = 0; i < 1; i++) {
    for (let i = 0; i < data.length; i++) {
      const nftData = await mintContract.tickets(Number(data[i].tokenId));
      let status_ = await mintContract.status();
      const totalSupply = await mintContract.totalSupply();
      const current_rank = await mintContract.current_rank();
      const total_tickets_remaining =
        await mintContract.total_tickets_remaining();

      let status;
      let image;
      let mapImage;
      let level;

      const P = (total_tickets_remaining / totalSupply) * 100;

      if (status_ == 1) {
        status = "Still climbing";
        image = `/assets/videos/1.mp4`;
        mapImage = `/assets/images/cards/climbing.png`;
        level = 1;
      } else if (status_ == 2) {
        if (nftData.rank == 0) {
          status = "Still climbing";
          image = `/assets//videos/1.mp4`;
          mapImage = `/assets/images/cards/climbing.png`;

          level = 1;

          if (P <= 80) level = 2;
          if (P <= 60) level = 3;
          if (P <= 40) level = 4;
          if (P <= 20) level = 5;
          if (P <= 12) level = 6;
          if (P <= 8) level = 7;
          if (P <= 3) level = 8;
          if (P <= 2) level = 9;
          if (P <= 1) level = 10;
        } else {
          status = "Eliminated";
          image = `/assets//videos/2.mp4`;
          mapImage = `/assets/images/cards/eliminated.png`;

          const p_ = (nftData.rank / totalSupply) * 100;

          level = 1;

          if (p_ <= 80) level = 2;
          if (p_ <= 60) level = 3;
          if (p_ <= 40) level = 4;
          if (p_ <= 20) level = 5;
          if (p_ <= 12) level = 6;
          if (p_ <= 8) level = 7;
          if (p_ <= 3) level = 8;
          if (p_ <= 2) level = 9;
          if (p_ <= 1) level = 10;
        }
      } else if (status_ == 3) {
        if (Number(nftData.rank) == 1) {
          status = "Winner";
          image = `/assets//videos/3.mp4`;
          mapImage = `/assets/images/cards/winner.png`;
          level = 10;
        } else {
          status = "Eliminated";
          image = `/assets//videos/2.mp4`;
          mapImage = `/assets/images/cards/eliminated.png`;

          const p_ = (nftData.rank / totalSupply) * 100;

          level = 1;

          if (p_ <= 80) level = 2;
          if (p_ <= 60) level = 3;
          if (p_ <= 40) level = 4;
          if (p_ <= 20) level = 5;
          if (p_ <= 12) level = 6;
          if (p_ <= 8) level = 7;
          if (p_ <= 3) level = 8;
          if (p_ <= 2) level = 9;
          if (p_ <= 1) level = 10;
        }
      }

      let eligible = false;
      let reward = 0;

      for (let j = 0; j < 5; j++) {
        if (
          Number(nftData.rank) >= Number(tiers[j].highestRank) &&
          Number(nftData.rank) <= Number(tiers[j].lowestRank) &&
          nftData.rank != 0
        ) {
          eligible = true;
          reward = tiers[j].reward;
          if (j == 0) image = "/assets//videos/2.mp4";
          if (j == 1) image = "/assets//videos/2.mp4";
          if (j == 2) image = "/assets//videos/2.mp4";
          if (j == 3) image = "/assets//videos/2.mp4";
          status = "Prize Winner";
          mapImage = `/assets/images/cards/winner.png`;

          break;
        }
      }

      data[i].image = image;
      data[i].status = status;
      data[i].comment = nftData.name;
      data[i].claimed = nftData.claimed;
      data[i].mapImage = mapImage;
      data[i].rank = nftData.rank;
      data[i].level = level;
      data[i].eligible = eligible;
      data[i].reward = reward;
    }

    setLoading(false);
    setMyNFTData(data);
    // setMyNFTData([data[0]]);
  }, [account, setMyNFTData, currentBlockNumber]);

  useEffect(() => {
    if (!account) return;

    fetchNFTsForContract();

    const refreshInterval = setInterval(fetchNFTsForContract, 10000);
    return () => clearInterval(refreshInterval);
  }, [account, setMyNFTData]);

  return { myNFTData, loading };
};

export default useNFTAlchemy;
