import styled from "styled-components";

const PrizeTableContainer = styled.table`
	background-color: rgba(111, 76, 255, 0.25);
	backdrop-filter: blur(10px);
	border-collapse: separate;
	border-spacing: 0;
	border-radius: 10px;
	tr {
		th {
			max-width: 10rem;
			padding: 4px 8px;
			text-align: center;
			border: solid 1px rgba(12, 10, 29, 0.85);
			font-size: 1.125rem;
		}
		td {
			padding: 4px 8px;
			text-align: right;
			border: solid 1px rgba(12, 10, 29, 0.85);
			padding: 10px;
		}
		.column-header {
			font-size: 1.125rem;
			text-align: left;
		}
	}
	.top-left-cell {
		border-top-left-radius: 10px;
	}
	.top-right-cell {
		border-top-right-radius: 10px;
	}
	.bottom-left-cell {
		border-bottom-left-radius: 10px;
	}
	.bottom-right-cell {
		border-bottom-right-radius: 10px;
	}
`;

export const PrizeTable = () => (
	<PrizeTableContainer>
		<thead>
			<tr>
				<th className="top-left-cell">Tier</th>
				<th>Winners</th>
				<th>% Chance of Winning</th>
				<th>Winning as Multiple of Entry</th>
				<th className="top-right-cell">ETH Prize Amount</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td className="column-header">Grand Prize</td>
				<td>1</td>
				<td>0.10%</td>
				<td>200x</td>
				<td>2</td>
			</tr>
			<tr>
				<td className="column-header">Tier 1</td>
				<td>2</td>
				<td>0.20%</td>
				<td>100x</td>
				<td>1</td>
			</tr>
			<tr>
				<td className="column-header">Tier 2</td>
				<td>10</td>
				<td>1.00%</td>
				<td>20x</td>
				<td>0.2</td>
			</tr>
			<tr>
				<td className="column-header">Tier 3</td>
				<td>100</td>
				<td>10.00%</td>
				<td>2x</td>
				<td>0.02</td>
			</tr>
			<tr>
				<td className="column-header bottom-left-cell">Total WInners</td>
				<td>113</td>
				<td>11.30%</td>
				<td></td>
				<td className="bottom-right-cell"></td>
			</tr>
		</tbody>
	</PrizeTableContainer>
);
