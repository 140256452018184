import { Suspense, useEffect } from 'react';
import styled from 'styled-components';
import { Loader } from '../../components/Loader';
import Mountainbg1 from "../../assets/images/Mountain-1.png";
import Mountainbg2 from "../../assets/images/Mountain-2.png";
import name from "../../assets/images/name.png";
import bgsignup from "../../assets/images/signup.png";
import bgsignup1 from "../../assets/images/signup-1.png";
import bgsignup2 from "../../assets/images/signup-2.png";
// import ProgressBar from "@ramonak/react-progress-bar";
import FaqComponent from '../../components/Faq'

const Wrapper = styled.div`
    position: relative;
    background-color: #3D8BFF;
    overflow: hidden; /* Ensure content doesn't overflow the wrapper */
`;

const Background = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    
`;
const Img = styled.img`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index:1;
    
`;
const Img2 = styled.img`
    position: absolute;
    width: 80%;
    top: 480px;
    left: 0;
    
`;
const Img3 = styled.img`
    position: absolute;
    width: 55%;
    top: 100px;
    left: 350px;
    z-index:0;
    
`;

const Content = styled.div`
    position: relative;
    z-index: 3; /* Ensure content is on top of images */
    padding: 20px; /* Adjust padding as needed */
    color: #fff; /* Example text color */
    display: flex;
    justify-content: center;
    top: 27%;
    left: 23%;
`;
const FirstSection = styled.div`
    height:100vh;
`;
const SecondSection = styled.div`
    height:100vh;
`;
const ThirdSection = styled.div`
    height:100vh;
`;
const FourthSection = styled.div`
    height:100vh;
`;
const Inner = styled.div`
   background: url(${bgsignup});
   background-size: cover;
`;
const Inner1 = styled.div`
   background: url(${bgsignup1});
   background-size: cover;
`;
const Inner2 = styled.div`
   background: url(${bgsignup2});
   background-size: cover;
`;


export const HomeNew = () => {
    useEffect(() => {
        document.body.classList.add('cstmbody');
      
        return () => {
          document.body.classList.remove('body');
        };
    }, []);

    return (
        <Wrapper className=''>
            <Suspense fallback={<Loader />}>
                <FirstSection>
                    <Background >
                        <Img src={Mountainbg1} alt="Mountain Background 1" /> 
                        <Img2 src={Mountainbg2} alt="Mountain Background 2" />
                        <Img3 src={name} alt="Rise To Top" />
                    </Background>

                    <Content>
                        <p>Riches await at the top of the mountain,<br/> but only for those who dare to climb.</p>
                    </Content>
                </FirstSection>

                <SecondSection className='container mt-[120px] '>
                    <h1 className='text-4xl '>OPEN SIGNUPS</h1>
                    
                    <div className='flex flex-row h-[29%] mt-3'>
                        <Inner className="w-[80%]">
                            {/* <Img4 src={bgsignup} alt="Rise To Top" /> */}
                            <div className='z-[1] absolute mt-[25px] ml-[25px]'>
                            <h3 className='text-[24px] font-bold'>Veggie Fresh Mountain challenge</h3>
                            <div className='flex flex-row gap-1'>
                                <h3 className='text-[16px]'>Grand Prize:</h3>
                                <h3 className='text-[16px] bg-white px-2 text-black'>20,000 SOL</h3>
                            </div>
                            <h3 className='text-[16px]'>Price: 20 SOL</h3>
                            {/* <ProgressBar className='cstmprogress' completed={45322} maxCompleted={100000} customLabel={`Signed Up: ${45322}/${100000}`} /> */}
                            <h3 className='text-[16px] text-[#FFFFFF80]'>Sign up Deadline: 10/05/23 12:00 AM EST</h3>
                            <h3 className='text-[16px] text-[#FFFFFF80]'>Climb Start: 10/06/23 12:00 AM EST</h3>
                            </div>
                        </Inner>
                        <div className="w-[20%]">
                            <button className="shadow-2xl w-full h-full hover:text-5xl hover:bg-[#fff] hover:text-[#000] hover:shadow-md hover:shadow-white">Join</button>
                        </div>
                    </div>

                    <div className='flex flex-row h-[29%] mt-5'>
                        <Inner1 className="w-[80%]">
                            {/* <Img4 src={bgsignup} alt="Rise To Top" /> */}
                            <div className='z-[1] absolute mt-[25px] ml-[25px]'>
                            <h3 className='text-[24px] font-bold'>Dangerous Cyber Mountain</h3>
                            <div className='flex flex-row gap-1'>
                                <h3 className='text-[16px]'>Grand Prize:</h3>
                                <h3 className='text-[16px] bg-white px-2 text-black'>20,000 SOL</h3>
                            </div>
                            <h3 className='text-[16px]'>Price: 20 SOL</h3>
                            {/* <ProgressBar className='cstmprogress' completed={45322} maxCompleted={100000} customLabel={`Signed Up: ${45322} / ${100000}`} /> */}
                            <h3 className='text-[16px] text-[#FFFFFF80]'>Sign up Deadline: 10/05/23 12:00 AM EST</h3>
                            <h3 className='text-[16px] text-[#FFFFFF80]'>Climb Start: 10/06/23 12:00 AM EST</h3>
                            </div>
                        </Inner1>
                        <div className="w-[20%]">
                            <button className="shadow-2xl w-full h-full hover:text-5xl hover:bg-[#fff] hover:text-[#000] hover:shadow-md hover:shadow-white">Join</button>
                        </div>
                    </div>

                    <div className='flex flex-row h-[29%] mt-5'>
                        <Inner2 className="w-[80%]">
                            {/* <Img4 src={bgsignup} alt="Rise To Top" /> */}
                            <div className='z-[1] absolute mt-[25px] ml-[25px]'>
                            <h3 className='text-[24px] font-bold'>Dangerous Cyber Mountain</h3>
                            <div className='flex flex-row gap-1'>
                                <h3 className='text-[16px]'>Grand Prize:</h3>
                                <h3 className='text-[16px] bg-white px-2 text-black'>20,000 SOL</h3>
                            </div>
                            <h3 className='text-[16px]'>Price: 20 SOL</h3>
                            {/* <ProgressBar className='cstmprogress' completed={45322} maxCompleted={100000} customLabel={`Signed Up: ${45322} / ${100000}`} /> */}
                            <h3 className='text-[16px] text-[#FFFFFF80]'>Sign up Deadline: 10/05/23 12:00 AM EST</h3>
                            <h3 className='text-[16px] text-[#FFFFFF80]'>Climb Start: 10/06/23 12:00 AM EST</h3>
                            </div>
                        </Inner2>
                        <div className="w-[20%]">
                            <button className="shadow-2xl w-full h-full hover:text-5xl hover:bg-[#fff] hover:text-[#000] hover:shadow-md hover:shadow-white">Join</button>
                        </div>
                    </div>
                </SecondSection>
                <ThirdSection className='container mt-[120px] '>
                    <h1 className='text-4xl '>ACTIVE CLIMBS</h1>
                    
                    <div className='flex flex-row h-[29%] mt-3'>
                        <Inner className="w-[80%]">
                            {/* <Img4 src={bgsignup} alt="Rise To Top" /> */}
                            <div className='z-[1] absolute mt-[25px] ml-[25px]'>
                            <h3 className='text-[24px] font-bold'>Veggie Fresh Mountain challenge</h3>
                            <div className='flex flex-row gap-1'>
                                <h3 className='text-[16px]'>Grand Prize:</h3>
                                <h3 className='text-[16px] bg-white px-2 text-black'>20,000 SOL</h3>
                            </div>
                            <h3 className='text-[16px]'>Price: 20 SOL</h3>
                            {/* <ProgressBar className='cstmprogress' completed={45322} maxCompleted={100000} customLabel={`Signed Up: ${45322}/${100000}`} /> */}
                            <h3 className='text-[16px] text-[#FFFFFF80]'>Sign up Deadline: 10/05/23 12:00 AM EST</h3>
                            <h3 className='text-[16px] text-[#FFFFFF80]'>Climb Start: 10/06/23 12:00 AM EST</h3>
                            </div>
                        </Inner>
                        <div className="w-[20%]">
                            <button className="shadow-2xl w-full h-full hover:text-5xl hover:bg-[#fff] hover:text-[#000] hover:shadow-md hover:shadow-white">Join</button>
                        </div>
                    </div>

                    <div className='flex flex-row h-[29%] mt-5'>
                        <Inner1 className="w-[80%]">
                            {/* <Img4 src={bgsignup} alt="Rise To Top" /> */}
                            <div className='z-[1] absolute mt-[25px] ml-[25px]'>
                            <h3 className='text-[24px] font-bold'>Dangerous Cyber Mountain</h3>
                            <div className='flex flex-row gap-1'>
                                <h3 className='text-[16px]'>Grand Prize:</h3>
                                <h3 className='text-[16px] bg-white px-2 text-black'>20,000 SOL</h3>
                            </div>
                            <h3 className='text-[16px]'>Price: 20 SOL</h3>
                            {/* <ProgressBar className='cstmprogress' completed={45322} maxCompleted={100000} customLabel={`Signed Up: ${45322} / ${100000}`} /> */}
                            <h3 className='text-[16px] text-[#FFFFFF80]'>Sign up Deadline: 10/05/23 12:00 AM EST</h3>
                            <h3 className='text-[16px] text-[#FFFFFF80]'>Climb Start: 10/06/23 12:00 AM EST</h3>
                            </div>
                        </Inner1>
                        <div className="w-[20%]">
                            <button className="shadow-2xl w-full h-full hover:text-5xl hover:bg-[#fff] hover:text-[#000] hover:shadow-md hover:shadow-white">Join</button>
                        </div>
                    </div>

                    <div className='flex flex-row h-[29%] mt-5'>
                        <Inner2 className="w-[80%]">
                            {/* <Img4 src={bgsignup} alt="Rise To Top" /> */}
                            <div className='z-[1] absolute mt-[25px] ml-[25px]'>
                            <h3 className='text-[24px] font-bold'>Dangerous Cyber Mountain</h3>
                            <div className='flex flex-row gap-1'>
                                <h3 className='text-[16px]'>Grand Prize:</h3>
                                <h3 className='text-[16px] bg-white px-2 text-black'>20,000 SOL</h3>
                            </div>
                            <h3 className='text-[16px]'>Price: 20 SOL</h3>
                            {/* <ProgressBar className='cstmprogress' completed={45322} maxCompleted={100000} customLabel={`Signed Up: ${45322} / ${100000}`} /> */}
                            <h3 className='text-[16px] text-[#FFFFFF80]'>Sign up Deadline: 10/05/23 12:00 AM EST</h3>
                            <h3 className='text-[16px] text-[#FFFFFF80]'>Climb Start: 10/06/23 12:00 AM EST</h3>
                            </div>
                        </Inner2>
                        <div className="w-[20%]">
                            <button className="shadow-2xl w-full h-full hover:text-5xl hover:bg-[#fff] hover:text-[#000] hover:shadow-md hover:shadow-white">Join</button>
                        </div>
                    </div>
                </ThirdSection>
                <FourthSection className='container mt-[120px] '>
                    <h1 className='text-4xl '>PREVIOUS CLIMBS</h1>
                    
                    <div className='flex flex-row h-[29%] mt-3'>
                        <Inner className="w-[80%]">
                            {/* <Img4 src={bgsignup} alt="Rise To Top" /> */}
                            <div className='z-[1] absolute mt-[25px] ml-[25px]'>
                            <h3 className='text-[24px] font-bold'>Veggie Fresh Mountain challenge</h3>
                            <div className='flex flex-row gap-1'>
                                <h3 className='text-[16px]'>Grand Prize:</h3>
                                <h3 className='text-[16px] bg-white px-2 text-black'>20,000 SOL</h3>
                            </div>
                            <h3 className='text-[16px]'>Price: 20 SOL</h3>
                            {/* <ProgressBar className='cstmprogress' completed={45322} maxCompleted={100000} customLabel={`Signed Up: ${45322}/${100000}`} /> */}
                            <h3 className='text-[16px] text-[#FFFFFF80]'>Sign up Deadline: 10/05/23 12:00 AM EST</h3>
                            <h3 className='text-[16px] text-[#FFFFFF80]'>Climb Start: 10/06/23 12:00 AM EST</h3>
                            </div>
                        </Inner>
                        <div className="w-[20%]">
                            <button className="shadow-2xl w-full h-full hover:text-5xl hover:bg-[#fff] hover:text-[#000] hover:shadow-md hover:shadow-white">Join</button>
                        </div>
                    </div>
                </FourthSection>

                <div id="faq">
                    <FaqComponent />
                </div>
            </Suspense>
        </Wrapper>
    );
};

export default HomeNew;
