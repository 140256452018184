import styled from "styled-components"
import MapHeader from "../../components/Header/mapHeader"

import Picture1 from '../../assets/images/Picture1.jpg';
import Picture2 from '../../assets/images/Picture2.jpg';
import Picture3 from '../../assets/images/Picture3.jpg';
import Picture4 from '../../assets/images/Picture4.png';
import Picture5 from '../../assets/images/Picture5.jpg';

const Title = styled.h1`
    font-weight: 600;
    font-size: 6rem;
    line-height: 1;
    letter-spacing: -2px;
    text-shadow: 0 0 80px rgb(192 219 255 / 48%), 0 0 32px rgb(65 120 255 / 24%);
    color: rgba(255, 255, 255, 0.96);
    pointer-events: none;
    margin-bottom: 3rem;
    margin-top: 0rem;

    @media(max-width: 768px) {
        font-size: 3rem;
        margin-bottom: 20px;
    }
`

const Spacer = styled.div`
    position: relative;
    height: 150px;
`

const Divider = styled.div`
    box-sizing: border-box;
    min-width: 0;
    position: relative;
    height: 1px;
    background-color: rgba(255,255,255,.16) !important;
    margin: 0 auto !important;
    max-width: none !important;
    width: 100% !important;

    &::before, &::after {
        content: close-quote;
        position: absolute;
        display: block;
        width: 7px;
        height: 7px;
        top: 0px;
        bottom: 0px;
        margin: auto 0;
        border: #85858f 1px solid;
        transform: rotate(45deg);
    }

    &::before {
        left: -8px;
    }

    &::after {
        right: -8px;
    }
`

const QuestionTitle = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
    text-shadow: 0 0 64px rgb(192 219 255 / 48%), 0 0 16px rgb(65 120 255 / 24%);
    letter-spacing: -1.2px;
    color: rgba(255, 255, 255, 0.96);
    margin-bottom: .5rem;
    text-align: left;

    &.font-small {
        font-size: .9rem;
    }
`

const QuestionAnswer = styled.div`
    font-size: 1rem;
    line-height: 2rem;
    color: rgba(255,255,255,.88);
    // max-width: 840px;
    width: 100%;
    margin: 0 auto;
    text-align: left;
`

const ContentWrapper = styled.div`
    max-width: 95%;
`

const TableWrapper = styled.div`
    transition: all .5s;

    table, th, td {
        padding: 16px;
        border: 1px solid rgba(255, 255, 255, 0.5);
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
        text-indent: 0;
    }

    th {
        background-color: rgba(255, 255, 255, 0.2);
        font-weight: 600;
        text-align: center;
    }

    *, *::before, *::after {
        box-sizing: border-box;
        position: relative;
        margin: 0;
        min-width: 0;
        min-height: 0;
        padding: 0;
        border: 0;
        border-radius: 0;
        background-color: transparent;
        background-image: none;
        background-position: center center;
        background-repeat: no-repeat;
    }
`

export const WhitePaper = () => {
  return (
    <>
      <MapHeader />

      <div className="container">
        <Spacer />

        <Title className="text-center">
          Rise To The Top <br /> Litepaper
        </Title>

        <Spacer />

        <div className="flex flex-col justify-center items-center">
          <ContentWrapper>
            <Divider />

            <div className="py-8">
              <QuestionTitle className="font-EuclidCircular">
                OVERVIEW
              </QuestionTitle>
              <QuestionAnswer className="font-EuclidCircular">
                Rise to the Top is a transparent on-chain elimination game featuring 1,000 uniquely named
                mountain climbers on the Arbitrum blockchain. A player’s minted climber NFT acts as their game
                piece, and climbers are eliminated one-by-one as they attempt to reach the top of the mountain!
                Players can check back during our elimination round phase to view the status of their climber(s) to
                see if they are still climbing. The higher up the mountain they make it, the more they win.              
              </QuestionAnswer>
              <img className="w-full h-[50%]" src={Picture1} alt="picture1" />
            </div>

            <Divider />

            <div className="py-8">
              <QuestionTitle className="font-EuclidCircular">
              ELIMINATION SCHEDULE
              </QuestionTitle>

              <QuestionAnswer className="font-EuclidCircular">
                The elimination will be rolling on a set schedule, powered by Chainlink Automation. Every four
                minutes, Chainlink Automation will call the Chainlink VRF on Arbitrum to return a random number.
                This random number will correspond to the NFT ID number that will be eliminated. This process
                will continue until all but one ticket has been eliminated. For a 1,000 ticket mint, this will take about
                three days for full elimination to occur.
                              
              </QuestionAnswer>

              <div className="flex flex-row items-center">
              <QuestionAnswer className="font-EuclidCircular">
                At a full mint out, there will be 113 winners, ranging from the 100 Tier 3 winners
                (prize is 2x original mint fee) all the way to our Grand Prize winner who will
                Rise to the Top and win 200x their original contribution!            
              </QuestionAnswer>
              <img className="w-[20%] h-[50%]" src={Picture2} alt="picture1" />

              </div>
            </div>

            <Divider />

            <div className="py-8">
              <QuestionTitle className="font-EuclidCircular">
              OUR GOALS
              </QuestionTitle>

              <QuestionAnswer className="font-EuclidCircular">
                The beauty of blockchain gaming is its transparency, and Rise to the Top’s goal is to create a
                gaming ecosystem that utilizes mechanisms where our players can clearly see the results of who is
                winning and who is losing. As we grow our community, our hope is to develop and offer increased
                strategy within our games, so that users experience greater challenges as they climb the mountain.
              </QuestionAnswer>
            </div>

            <Divider />

            <div className="py-8">
              <QuestionTitle className="font-EuclidCircular">
                VERIFIED FAIRNESS
              </QuestionTitle>

              <QuestionAnswer className="font-EuclidCircular">
                Our gaming mechanics are created with <span className="underline text-[#3FAA48] font-bold">Chainlink VRF </span> (Verifiable Random Functions) to provide
                provable randomness. Chainlink VRF is a provably fair and verifiable random number generator
                (RNG) that enables smart contracts to access random values without compromising security or
                usability. For each elimination round, Chainlink VRF generates the appropriate number of random
                values and cryptographic proof of how those values were determined. The proof is published and
                verified on-chain before any consuming applications can use it. This process ensures that results
                cannot be tampered with or manipulated by any single entity including oracle operators, miners,
                users, or smart contract developers.
                We have also We have also integrated<span className="underline text-[#3FAA48] font-bold"> Chainlink Automation</span> to update the newly eliminated ticket
                from the VRF results to the contract.       
                </QuestionAnswer>
                <img className="w-full h-[50%] my-4" src={Picture3} alt="picture1" />
                <QuestionAnswer className="font-EuclidCircular">
                -<span className="underline text-[#3FAA48] font-bold">  What is Chainlink VRF? (YouTube)</span>  <br/>
                - <span className="underline text-[#3FAA48] font-bold"> What is Chainlink Automation (YouTube)</span>  <br/><br/>
                With the results all on-chain, our community is able to monitor who wins and how many winners
                there are at each level. The elimination process is fully automated and will create a safe and secure
                environment for climbing. Winning will be able to claim their prizes directly from the smart contract
                (paying only the gas on the claim transaction).
                *Climber mint fee will include the cost of running VRF and Automation contract calls.       
              </QuestionAnswer>

              <QuestionTitle className="font-EuclidCircular font-small mt-4">
              PRIZE TIERS AND ODDS
              </QuestionTitle>

              <QuestionAnswer className="font-EuclidCircular">
                The chart below shows the number of winning climbers per prize tier, the percent chance each
                participating climber has of winning each tier, the prize per tier as a multiple of the entry price (mint
                fee), and the corresponding prize amounts for an entry fee of .01 ETH.
              </QuestionAnswer>
              <img className="w-full h-[50%] my-4" src={Picture4} alt="picture1" />


              <QuestionTitle className="font-EuclidCircular font-small mt-4">
                ENSURING THAT THE FUNDS ARE SAFE
              </QuestionTitle>

              <QuestionAnswer className="font-EuclidCircular">
                Mint funds are held within the contract until we achieve a successful mint-out. After successful
                mint-out, the rewards will remain within the smart contract until they are claimed by the winning
                climbers (who pay only the gas fee on the claim transaction).
                The contract address will be shared once the contract is deployed on mainnet. The Rise to the Top
                contract code will be available and published on Arbitrum explorer once it has been deployed.
              </QuestionAnswer>
              <img className="w-full h-[50%] my-4" src={Picture5} alt="picture1" />

            </div>


            {/* <div className="py-8">
              <QuestionTitle className="font-EuclidCircular">
                The Numbers
              </QuestionTitle>

              <QuestionAnswer className="font-EuclidCircular">
                The payout structure below shows winning quantities for a full mint out where each ticket costs 0.5 Eth.
              </QuestionAnswer>
            </div>

            <Divider />

            <div className="py-8">
              <QuestionTitle className="font-EuclidCircular">
                Winners<span className="font-normal">- Breakdown from a .4 E Mint</span>
              </QuestionTitle>

              <QuestionAnswer className="font-EuclidCircular">
                <TableWrapper className={`font-EuclidCircular w-full snowLift relative mt-4`}>
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th align="center">Number of Winners</th>
                        <th align="center">Prize Value</th>
                        <th align="center">Total Available rewards</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td align="center">1</td>
                        <td align="center">1,000 Eth</td>
                        <td align="center">1,000 Eth</td>
                      </tr>

                      <tr>
                        <td align="center">2</td>
                        <td align="center">750 Eth</td>
                        <td align="center">1,500 Eth</td>
                      </tr>

                      <tr>
                        <td align="center">10</td>
                        <td align="center">50 Eth</td>
                        <td align="center">500 Eth</td>
                      </tr>

                      <tr>
                        <td align="center">100</td>
                        <td align="center">5 Eth</td>
                        <td align="center">500 Eth</td>
                      </tr>

                      <tr>
                        <td align="center">1,000</td>
                        <td align="center">1 Eth</td>
                        <td align="center">1,000 Eth</td>
                      </tr>
                    </tbody>
                  </table>
                </TableWrapper>
              </QuestionAnswer>
            </div>

            <Divider />

            <div className="py-8">
              <QuestionTitle className="font-EuclidCircular">
                Elimination timing
              </QuestionTitle>

              <QuestionAnswer className="font-EuclidCircular">
                The timing of tickets being eliminated should slow down as more tickets get eliminated, which will promote secondary sales and is subject to change according to our launch window. Our team reserves the right to increase or decrease the elimination round period according to market conditions as well as readiness of our community.

                <TableWrapper className={`font-EuclidCircular w-full snowLift relative mt-4`}>
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th align="center">Days since inception</th>
                        <th align="center">% of tickets to eliminate</th>
                        <th align="center">Cumulative % eliminated</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td align="center">0</td>
                        <td align="center">20%</td>
                        <td align="center">20%</td>
                      </tr>

                      <tr>
                        <td align="center">.5</td>
                        <td align="center">20%</td>
                        <td align="center">40%</td>
                      </tr>

                      <tr>
                        <td align="center">1</td>
                        <td align="center">20%</td>
                        <td align="center">60%</td>
                      </tr>

                      <tr>
                        <td align="center">1.5</td>
                        <td align="center">20%</td>
                        <td align="center">80%</td>
                      </tr>

                      <tr>
                        <td align="center">2</td>
                        <td align="center">8.87% (last losers cut)</td>
                        <td align="center">88.87% (winner cutoff)</td>
                      </tr>

                      <tr>
                        <td align="center">3</td>
                        <td align="center">5% (half of level 5 winners)</td>
                        <td align="center">93.87%</td>
                      </tr>

                      <tr>
                        <td align="center">3.5</td>
                        <td align="center">5% (half of level 5 winners)</td>
                        <td align="center">98.87%</td>
                      </tr>

                      <tr>
                        <td align="center">4</td>
                        <td align="center">0.5% (half of level 4 winners)</td>
                        <td align="center">99.37%</td>
                      </tr>

                      <tr>
                        <td align="center">4.5</td>
                        <td align="center">0.5% (half of level 4 winners)</td>
                        <td align="center">99.87%</td>
                      </tr>

                      <tr>
                        <td align="center">5</td>
                        <td align="center">0.05% (half of level 3 winners)</td>
                        <td align="center">99.92%</td>
                      </tr>

                      <tr>
                        <td align="center">5.5</td>
                        <td align="center">0.05% (half of level 3 winners)</td>
                        <td align="center">99.97%</td>
                      </tr>

                      <tr>
                        <td align="center">6</td>
                        <td align="center">0.02% (level 2 winners)</td>
                        <td align="center">99.99%</td>
                      </tr>
                    </tbody>
                  </table>
                </TableWrapper>
              </QuestionAnswer>
            </div>

            <Divider />

            <div className="py-8">
              <QuestionTitle className="font-EuclidCircular">
                Team
              </QuestionTitle>

              <QuestionAnswer className="font-EuclidCircular">
                The RTTT Team comes from a mix of backgrounds and is composed of web2 game devs, full stack blockchain developers, multi chain crypto maxis and NFT aficionados. We spend our lives building bridges from web2 to web3 and this is the first of many games we plan to build in this decentralized world that we envision.
              </QuestionAnswer>
            </div>

            <Divider />

            <div className="py-8">
              <QuestionTitle className="font-EuclidCircular">
                Roadmap
              </QuestionTitle>

              <QuestionAnswer className="font-EuclidCircular">
                Our first RTTT game on the Ethereum network seeks to make a name for itself as we provide simple, transparent gaming with high value rewards. Our hope is to offer RTTT games often and branch to multiple chains as our popularity gains attention.
              </QuestionAnswer>
            </div>

            <Divider />

            <QuestionAnswer className="font-EuclidCircular py-8">
              Riches await at the top of the mountain, but only for those who climb! Climb with us at <a href="https://risetothetop.xyz/ " target={'_blank'} className="text-blue-500" >https://risetothetop.xyz/ </a>
            </QuestionAnswer> */}
          </ContentWrapper>

          <Spacer />
        </div>
      </div>
    </>
  )
}

export default WhitePaper