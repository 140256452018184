export const range = {
  x: 1800,
  y: 900,
};

export const zoomRatio = range.x / window.innerWidth;

export const zoomRange = {
  min: 1,
  max: 10,
};

export const miniMapSize = {
  width: 300,
  height: 200,
};
export const levelArea = {
  10: {
    position: [
      {
        x: -30,
        y: 330,
      },
      {
        x: -31,
        y: 329,
      },
    ],
    size: 25,
    count: 1,
  },
  9: {
    position: [
      {
        x: 99,
        y: 165,
      },
      {
        x: -160,
        y: 175,
      },
    ],
    size: 20,
    count: 2,
  },
  8: {
    position: [
      {
        x: 360,
        y: 35,
      },
      {
        x: -340,
        y: 45,
      },
    ],
    size: 17,
    count: 10,
  },
  7: {
    position: [
      {
        x: 500,
        y: -5,
      },
      {
        x: -381,
        y: -15,
      },
    ],
    size: 16,
    count: 100,
  },
  6: {
    position: [
      {
        x: 567,
        y: -65,
      },
      {
        x: -453,
        y: -75,
      },
    ],
    size: 15,
    count: 1000,
  },
  5: {
    position: [
      {
        x: 567,
        y: -150,
      },
      {
        x: -453,
        y: -160,
      },
    ],
    size: 15,
    count: 1000,
  },
  4: {
    position: [
      {
        x: 567,
        y: -220,
      },
      {
        x: -453,
        y: -221,
      },
    ],
    size: 15,
    count: 1000,
  },
  3: {
    position: [
      {
        x: 567,
        y: -280,
      },
      {
        x: -453,
        y: -281,
      },
    ],
    size: 15,
    count: 1000,
  },
  2: {
    position: [
      {
        x: 567,
        y: -330,
      },
      {
        x: -453,
        y: -331,
      },
    ],
    size: 15,
    count: 1000,
  },
  1: {
    position: [
      {
        x: 667,
        y: -390,
      },
      {
        x: -655,
        y: -391,
      },
    ],
    size: 15,
    count: 10000,
  },
};
