import styled from "styled-components";
import MapHeader from "../../components/Header/mapHeader";
import { Loader } from "../../components/Loader";
import { formattedNumber } from "../../helper";
import { useActiveWeb3React } from "../../hooks";
import useMintNFT from "../../hooks/useMintNFT";
import useNFTAlchemy from "../../hooks/useNFTAlchemy";
import { NormalButton } from "../../theme/components";
import { ReactNode } from "react";

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  min-height: 100vh;

  .nftWrapper {
    min-height: 550px;
  }
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 3rem;
  text-shadow: 0 0 80px rgb(192 219 255 / 48%), 0 0 32px rgb(65 120 255 / 24%);
  letter-spacing: -1.2px;
  color: rgba(255, 255, 255, 0.96);
  margin-bottom: 1.2rem;

  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 3rem;
  }
`;

const Spacer = styled.div`
  height: 100px;
`;

const ItemWrapper = styled.div`
  background-color: transparent;
  box-shadow: rgb(20 26 40 / 20%) 0px 7px 42px;
  border-radius: 4px;
  // overflow: hidden;
  will-change: transform;
  transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);
  margin: 1rem;

  .wrapper {
    border-radius: 4px;
    // overflow: hidden;
    max-width: 280px;

    .imgBack {
      position: absolute;
      top: 0px;
      height: 220px;
      width: 100%;
      background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.08) 100%
        ),
        linear-gradient(
          180deg,
          rgba(12, 10, 29, 0) 0%,
          rgba(12, 10, 29, 0.4) 100%
        );
    }

    video {
      height: 220px;
      object-fit: cover;
    }

    .descriptionWrapper {
      padding: 1rem 1.5rem;
      text-align: left;
      width: 100%;
      bottom: 0px;
      background: linear-gradient(
          180deg,
          #0c0a1d 0%,
          rgba(12, 10, 29, 0.32) 100%
        ),
        rgba(255, 255, 255, 0.08);
      border-radius: 0 0 8px 8px;

      .title {
        h3 {
          margin-bottom: 8px;
          font-size: 1.17rem;
        }
      }

      .description {
        margin: 0;
        min-width: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .date {
          opacity: 0.3;
        }
      }
    }
  }
`;

export const EmptyWrapper = styled.div`
  font-size: 2rem;
  border: 1px solid #50ab41;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background: #348d1c5c;
`;

export const NFTs = () => {
  const { account } = useActiveWeb3React();

  const { myNFTData, loading } = useNFTAlchemy();

  const { claimRewards, status } = useMintNFT();

  const isEmpty = myNFTData.length === 0;

  return (
    <>
      <MapHeader />

      <Wrapper className="container w-full font-EuclidCircular">
        <Spacer />

        <Title className="font-Outfit">My RiseToTop Climbers</Title>

        <div className="flex flex-wrap justify-center items-center mt-8 relative nftWrapper">
          {!account ? (
            <EmptyWrapper>You didn't connect wallet.</EmptyWrapper>
          ) : loading ? (
            <Loader />
          ) : isEmpty ? (
            <EmptyWrapper>You don't have any NFTs.</EmptyWrapper>
          ) : (
            myNFTData.map((item: any, index: number) => (
              <ItemWrapper key={`nftdata${index}`}>
                <div className="wrapper">
                  <div className="imgBack" />

                  <video muted autoPlay loop playsInline>
                    <source src={item.image} type="video/mp4" />
                  </video>

                  <div className="descriptionWrapper">
                    <div className="title">
                      <h3>#{formattedNumber(Number(item.tokenId))}</h3>
                      {/* Level: {Number(item.level)} */}
                    </div>

                    <div className="description">
                      <small>{item.comment}</small> &nbsp;&nbsp;
                      <small className="date">{item.status}</small>
                    </div>

                    <div className="description">
                      <small>Rank</small> &nbsp;&nbsp;
                      <small className="date">{Number(item.rank)}</small>
                    </div>

                    <div className="w-full flex justify-center mt-2">
                      <NormalButton
                        disabled={item.claimed || status != 3 || !item.eligible}
                        onClick={() => claimRewards({ index: item.tokenId })}
                      >
                        Claim{" "}
                        {item.reward != 0 && (
                          <>{Number(item.reward / 1e18)} ETH </>
                        )}
                        {item.claimed ||
                          status != 3 ||
                          (!item.eligible && (
                            <span className="tooltip left font-EuclidCircular">
                              {status != 3 &&
                                "Claim is available after the elimination session is over."}
                              {item.claimed && "Ticket already claimed."}
                              {status == 3 &&
                                !item.eligible &&
                                "Ticket not eligible for reward."}
                            </span>
                          ))}
                      </NormalButton>
                    </div>
                  </div>
                </div>
              </ItemWrapper>
            ))
          )}
        </div>
      </Wrapper>
    </>
  );
};

export default NFTs;
