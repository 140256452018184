export * from "./network";
export * from "./mapConfigs";

export const ErrorMessages = {
	"-32002":
		"Already processing Metamask wallet connect. Please confirm metamask modal.",
};

export const ApplicationModal = {
	WALLET: 1,
	RANKING: 2,
};
